import React from 'react'
import { PageRendererProps } from 'gatsby'
import ScheduleComponent from '../components/live/schedule'
import Layout from '../templates/Layout'
import styled from 'styled-components'
import { PHONE } from '../constants/measurements'
import { withErrorHandler } from '../components/errorHandler'

export const Wrap = styled.div`
  margin-top: 20px;
  padding: 60px;
  @media (max-width: ${PHONE}) {
    margin-top: 0;
    padding: 15px;
  }
`

const AthleteSchedule = (props: PageRendererProps): React.ReactElement => (
  <Layout>
    <Wrap>
      <ScheduleComponent location={props.location} />
    </Wrap>
  </Layout>
)

export default withErrorHandler(AthleteSchedule)
